import styles from "./zunuAi.module.scss";
import { ReactComponent as IconMail } from "../../assets/svg/envelope.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState } from "react";
import { Helmet } from "react-helmet";

type ZunuAIFormFields = {
  email: string;
};

export default function ZunuAi() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues: ZunuAIFormFields = { email: "" };

  const { handleSubmit, register, reset } = useForm({
    mode: "all",
    defaultValues: initialValues,
  });

  const onFormSubmit = async (data: ZunuAIFormFields) => {
    console.log("[ZunuAi][onFormSubmit]", { data });

    setIsLoading(true);

    const formData = new FormData();

    formData.append("name", data.email);
    formData.append("email", data.email);
    formData.append("subject", "Zunu Ai - Beta or Talk to us");
    formData.append("description", data.email);
    formData.append("status", "2");
    formData.append("priority", "1");
    formData.append("group_id", "150000373810");

    const res = await fetch(
      "https://website-svc.vault.ziroh.com/api/v1/ticket",
      {
        method: "POST",
        body: formData,
      }
    );

    const parsedRes = await res.json();
    console.log({ parsedRes });

    if (parsedRes.errorCode === 0) {
      toast.success("Successfully sent");
      reset();
    } else {
      toast.error("Failed to send");
    }

    setIsLoading(false);
  };

  return (
    <>
      <section className={styles.zunu_ai_section}>
        <article className={styles.zunu_ai_article}>
          <h3>Zunu AI</h3>
          <p>
            Build large-scale models effortlessly, securely, and at a fraction
            of the cost
          </p>
          <p>No GPU is required, Fully privacy-preserving</p>
        </article>

        <div className={styles.zunu_ai_cs}>
          <h3>Coming soon</h3>
        </div>

        <form
          className={styles.zunu_ai_form}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <p className={styles.zunu_ai_form_title}>
            Apply for Beta or Talk to us
          </p>

          <div className={styles.zunu_ai_input}>
            <IconMail className={styles.zunu_ai_input_icon} />
            <input
              type="email"
              required
              placeholder="Mail id"
              {...register("email", { required: "Required" })}
            />
            <button disabled={isLoading}>Let's talk</button>
          </div>
        </form>
      </section>
    </>
  );
}
