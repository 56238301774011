import React from "react";
import "./App.scss";
import { Navbar } from "./_shared/components/Navbar/Navbar";
import ZunuAi from "./Pages/ZunuAi/zunuAi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <ZunuAi />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"dark"}
      />
    </div>
  );
}

export default App;
